@use '../../styles/yr' as *;

.map-type-button {
  box-shadow: $theme-box-shadow-elevated;

  &[aria-expanded='true'] {
    // OVERRIDE(tl): We want to turn the chevron upside down when the button in expanded
    .button__icon-after {
      transform: rotate(180deg);
    }
  }
}
