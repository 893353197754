@use '../../styles/yr' as *;

.map-attribution {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 0 size(0.5) 0 0;
  display: block;
  padding: 0 rem(2px);
  text-decoration: none;
  width: max-content;
}
