@use '../../styles/yr' as *;

.map-footer {
  display: flex;
  background-color: var(--color-background-base);
  justify-content: space-between;
  padding: size(1);
}

.map-footer__attribute {
  display: flex;
  gap: size(1);
  align-items: center;
}

.map-footer__attribute-yr-logo {
  color: var(--color-logo-primary-bg-rest);
  opacity: 0.9;
}

.map-footer__attribute-map-type {
  align-self: center;
}

.map-footer__link-container {
  align-self: center;
}

.map-footer__link {
  display: inline-flex;
  cursor: pointer;
}

.map-footer__link-icon {
  margin-left: size(0.5);
  align-self: center;
}

@include mq-lte($mq-450) {
  .map-footer {
    flex-direction: column;
  }

  .map-footer__link-container {
    align-self: flex-start;
    padding-top: size(1);
  }
}
