@use '../../styles/yr' as *;

@import '../../../node_modules/maplibre-gl/dist/maplibre-gl.css';

$map-z-index-attribution: 1;
$map-z-index-legend: 1;
$map-z-index-ui: 2;

.map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  // Hide popups and the beta label when they are partially ouside the map container
  overflow: hidden;

  // Contain z-indexes
  z-index: 0;
}

.map--rounded,
.map__map-in-map--rounded {
  border-radius: size(1);
  border: $divider-solid;
}

.map[data-cursor='arrow'] {
  canvas,
  .map__primary-marker,
  .map__secondary-marker {
    cursor: default;
  }

  // Add cursor style to indicate that marker is clickable in wind map
  &[data-map-type='wind'] .map__primary-marker,
  &[data-map-type='lightning'] .map__primary-marker,
  &[data-map-type='radar'] .map__primary-marker {
    cursor: pointer;
  }

  &[data-is-dragging='true'] canvas,
  &[data-is-dragging='true'] .map__primary-marker,
  &[data-is-dragging='true'] .map__secondary-marker {
    cursor: move;
  }
}

.map__map {
  height: 100%;
  .map[data-embedded='true'] & {
    pointer-events: none;
  }
}

.map__layout {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  // The layout is positioned over the entire map so we ignore all pointer events on this element
  // and allow pointer events again on children that should be interactive.
  pointer-events: none;
}

.map__scale-line {
  left: 0;
  padding: 1rem;
  position: absolute;
  top: 0;
  z-index: $map-z-index-ui;

  &[data-has-beta-label='true'] {
    left: size(3);
    top: size(4);
  }
}

.map__fullscreen-button {
  position: absolute;
  top: size(2);
  right: size(2);
  z-index: $map-z-index-ui;
  pointer-events: all;
}

.map__beta-label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $map-z-index-ui;

  // Make the children/content of the portal interactive.
  // We apply this style to the immediate children instead of applying the style to
  // this element itself because this element might be larger than the element within.
  & > * {
    pointer-events: all;
  }
}

.map__portal[data-type='legend'] {
  left: size(2);
  top: size(6.5);

  z-index: $map-z-index-legend;

  &[data-has-beta-label='true'] {
    top: size(10);
    left: size(4.5);
  }
}

.map__zoom-toolbar {
  position: absolute;
  top: rem(75);
  right: size(2);
  z-index: $map-z-index-ui;
  pointer-events: all;
}

.map__portal[data-type='a11y'] {
  position: absolute;
  top: rem(170);
  right: size(2);
  z-index: $map-z-index-ui;
  pointer-events: all;
}

.map__map-in-map {
  // We use `!important` to overwrite style set from maplibregl
  position: absolute !important;
  bottom: size(2);
  right: size(2);

  border: $divider-solid;

  width: 25%;
  // Since padding is calculated based on width, we can use padding-bottom to give the element the same height as width
  height: 0;
  padding-bottom: 25%;

  z-index: $map-z-index-ui;

  pointer-events: all;

  @include mq-lte($mq-map-desktop) {
    // To avoid map-in-map being to small on smaller devices, we hardcode the size
    width: rem(120px);
    height: rem(120px);
    padding-bottom: 0;
  }
}

.map__bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  @include mq-gte($mq-map-desktop) {
    padding-bottom: size(8);
  }

  .map[data-embedded='true'] & {
    padding-bottom: size(8);

    @include mq-lte($mq-450) {
      padding-bottom: size(12);
    }
  }
}

.map__info-and-selector {
  display: flex;
  flex-direction: column;
  gap: size(2);
  align-items: flex-end;
  padding-right: size(2);

  @include mq-gte($mq-map-desktop) {
    position: absolute;
    right: 0;
    bottom: size(8);
  }
}

.map__type-selector {
  pointer-events: all;
  z-index: $map-z-index-ui;
}

.map__attribution {
  position: relative;
  z-index: $map-z-index-attribution;
  pointer-events: all;

  @include mq-gte($mq-map-desktop) {
    bottom: size(14);
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .map[data-embedded='true'] & {
    position: absolute;
    left: auto;
    bottom: auto;
    top: 0;
    right: 0;
  }
}

.map__footer {
  position: absolute;
  bottom: 0;
  margin: 0;
  width: 100%;
  z-index: 2;
  pointer-events: all;
}

.map__portal {
  position: relative;
  z-index: $map-z-index-ui;

  // Make the children/content of the portal interactive.
  // We apply this style to the immediate children instead of applying the style to
  // this element itself because this element might be larger than the element within.
  & > * {
    pointer-events: all;
  }
}

.map__primary-marker {
  position: relative;
  width: size(5);
  height: size(5);

  .map[data-embedded='true'] & {
    visibility: hidden;
  }
}

.map__secondary-marker {
  position: relative;
  width: size(1);
  height: size(1);
}

.map__primary-marker-pin,
.map__secondary-marker-pin,
.map__secondary-marker-pin--hover {
  width: 100%;
  height: 100%;
}

.map__primary-marker-pin--hover {
  display: none;
}

.map__primary-marker:hover > .map__primary-marker-pin {
  display: none;
}

.map__primary-marker:hover > .map__primary-marker-pin--hover {
  display: unset;
}

.map__primary-marker-shadow,
.map__secondary-marker-shadow {
  position: absolute;
  top: 100%;
  transform: translateY(-55%);

  // Need to set z-index to make the shadow go behind the marker
  z-index: -1;
}

.map__error {
  text-align: center;
  margin-top: size(10);
}
