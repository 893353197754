@use '../../styles/yr' as *;

.map-beta-label {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: rem(50px * 2);
  height: rem(50px);
  transform-origin: top center;
  transform: translateX(-50%) rotate(-45deg);
  text-decoration: none;
}

.map-beta-label__content {
  background-color: var(--color-background-subtle-core-blue);
  color: var(--color-text-base);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: size(0.5);
}
