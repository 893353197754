@use '../../styles/yr' as *;

.map-zoom-toolbar {
  display: grid;
  grid-template-rows: 1fr 1fr;
  width: fit-content;

  box-shadow: $theme-box-shadow-elevated;
  border-radius: size(2.5);

  background-color: var(--color-background-base);
}
