@use '../../styles/yr' as *;

.map-type-selector__dialog {
  display: flex;
  flex-direction: column;
  padding: size(2);
  width: fit-content;
  background-color: var(--color-background-base);
  border-radius: size(1);
  box-shadow: $theme-box-shadow-elevated;
}

.map-type-selector__dialog-header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: size(1.5);
}

.map-type-selector__dialog-image-row {
  display: grid;
  grid-auto-flow: column;
  gap: size(1);

  // This will need to be adjusted if we ever get over 6 maps
  grid-template: repeat(2, 1fr) / repeat(3, 1fr);
  grid-auto-flow: row;
}

.map-type-selector__type-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;

  &:hover,
  &[aria-current='page'] {
    .map-type-selector__type-button-title {
      color: var(--color-stroke-active);
    }
    .map-type-selector__type-button-border-container {
      &::before {
        display: block;
        content: '';
        border: 2px solid var(--color-stroke-active);
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: size(0.5);
      }
    }
  }
}

.map-type-selector__type-button-border-container {
  position: relative;
}

.map-type-selector__type-button-image {
  border-radius: size(0.5);
}

.map-type-selector__type-button-title {
  padding-top: size(0.5);
}
